<!--
 * @Author: gjm
 * @Date: 2022-08-08 08:08:08
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-09-02 17:38:29
 * @Description: 在线咨询
-->
<style lang="less" scoped>
.drawer-box {
  padding: 24px 20px;
  box-sizing: border-box;
}

.create-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.wenda {
  position: relative;
  width: 100%;
  min-height: 114px;
  background-color: #f2f6fb;
  border-radius: 10px;
  margin-bottom: 15px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 70px;
  box-sizing: border-box;
  overflow: hidden;
}

.question-box {
  width: 100%;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  color: 14px;
}

.question-left {
  display: flex;
  width: 85%;
}

.question-icon {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: rgba(64, 128, 255, 0.15);
  line-height: 22px;
  text-align: center;
  color: #4080ff;
}

.invalid {
  color: #8895ab !important;
  background: rgba(136, 149, 171, 0.15) !important;
}

.invalid-a {
  color: #fff !important;
  background: rgba(136, 149, 171) !important;
}

.greytext {
  color: #8895ab !important;
}

.question-text {
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 100px);
}

.question-attachment {
  color: #4080ff;
  height: 28px;
  span {
    line-height: 28px;
  }
}

.question-status1 {
  width: 56px;
  height: 20px;
  font-size: 12px;
  border-radius: 2px;
  color: #fb5549;
  background-color: rgba(251, 85, 73, 0.1);
  line-height: 20px;
  text-align: center;
}

.question-status2 {
  width: 56px;
  height: 20px;
  font-size: 12px;
  border-radius: 2px;
  color: #53d478;
  background-color: rgba(83, 212, 120, 0.1);
  line-height: 20px;
  text-align: center;
}

.question-status3 {
  width: 56px;
  height: 20px;
  font-size: 12px;
  border-radius: 2px;
  color: #8895ab;
  background-color: rgba(136, 149, 171, 0.1);
  line-height: 20px;
  text-align: center;
}

.question-right {
  display: flex;
  color: #4080ff;
}

.question-continue {
  color: #4080ff;
  cursor: pointer;
  margin-left: 7px;
  text-align: right;
}

.question-close {
  &_btn:hover {
    cursor: pointer;
  }
}

.qa-box {
  width: 100%;
}

.answer-box {
  margin-top: 16px;
  margin-bottom: 20px;
  display: flex;
  font-size: 14px;
}

.answer-icon {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #4080ff;
  line-height: 22px;
  text-align: center;
  color: white;
}

.answer-text {
  margin-left: 10px;
}

.line {
  border-top: 1px dashed #d4dae5;
  height: 1px;
  margin-top: 15px;
}

// 追问
.more-box {
  margin-left: 30px;
}

.one-more-box {
  display: flex;
  margin-top: 15px;
}

.more-title {
  color: #4080ff;
  font-size: 14px;
  width: 50px;
}

.more-question,
.more-answer {
  display: flex;
  width: 100%;
}

.qa-information {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  font-size: 14px;
  background: rgba(64, 128, 255, 0.15);
}

.info-left {
  margin-right: 95px;
}

.info-title {
  color: #4080ff;
  font-weight: 500;
}

.question-detail {
  cursor: pointer;
}

.answer-text {
  width: calc(85% - 100px);
}
.question-title {
  width: calc(100% - 120px);
}
.answer-content {
  width: calc(100% - 120px);
}

/deep/ .icon-question-detail svg {
  width: 10px !important;
}
</style>

<template>
  <a-drawer :width="900" :title="$t('orderInfo.btn_online_consult')" :visible="visible" @close="handleClose">
    <div class="drawer-box">
      <div class="create-box">
        <div class="btn-create">
          <a-button type="primary" @click="createQuestion"> + 创建问题 </a-button>
        </div>
        <div class="search-box">
          <span style="margin-right: 10px">状态</span>
          <a-select
            v-model="selectValue"
            style="width: 150px"
            placeholder="请选择状态"
            @change="selectStatus"
            allowClear
          >
            <a-select-option value="1"> 待回复 </a-select-option>
            <a-select-option value="2"> 已回复 </a-select-option>
            <a-select-option value="3"> 已关闭 </a-select-option>
          </a-select>
        </div>
      </div>

      <div class="wenda" :key="item.id" v-for="item in questionList">
        <!-- 问 -->
        <div class="question-box">
          <div class="question-left">
            <div :class="item.status == 3 ? 'question-icon invalid' : 'question-icon'">Q</div>
            <div class="question-text">
              <div class="question-content text-wrap">{{ item.questionContext }}</div>
              <a
                :href="item.attachmentFile | accelerateUrl"
                class="question-attachment dis-ib ellipsis"
                v-if="item.attachmentFile"
                :title="item.attachmentName"
                style="width: 500px"
              >
                <img class="mr-1" :src="require('@/assets/image/link_icon1.png')" alt="" height="16px" />
                <span>{{ item.attachmentName }}</span>
              </a>
            </div>
            <div class="question-status1" v-if="item.status == 1">待回复</div>
            <div class="question-status2" v-if="item.status == 2">已回复</div>
            <div class="question-status3" v-if="item.status == 3">关闭</div>
          </div>

          <div class="question-right">
            <div
              class="question-continue"
              @click="handleOpenReply(item)"
              v-if="
                ((item.questionerType == 1 && $g.isSup) || (item.questionerType == 2 && $g.isCust)) &&
                item.status == 1 &&
                !item.orderQuestionReplyDTOList
              "
            >
              回应
            </div>
            <div
              class="question-continue"
              @click="handleOpenCuntinue(item)"
              v-if="
                ((item.questionerType == 1 && $g.isCust) || (item.questionerType == 2 && $g.isSup)) &&
                item.orderQuestionReplyDTOList &&
                item.status == 2 &&
                !item.orderQuestionDTOList &&
                item.status != 3
              "
            >
              追问
            </div>

            <div class="question-close question-continue">
              <a-popconfirm
                placement="bottomRight"
                ok-text="确认"
                cancel-text="取消"
                @confirm="handleCloseConfirm(item.id)"
              >
                <template #title>
                  <p>你是否要确认关闭该问题？</p>
                </template>
                <div
                  v-if="
                    ((item.questionerType == 1 && $g.isCust) || (item.questionerType == 2 && $g.isSup)) &&
                    item.status != 3
                  "
                >
                  关闭
                </div>
              </a-popconfirm>
            </div>
            <div
              class="question-detail question-continue"
              @click="showDetail(item.id)"
              v-if="item.orderQuestionDTOList"
            >
              详情
              <a-icon v-if="!item.showQuestionDetail" type="down" class="icon-question-detail" />
              <a-icon v-else type="up" class="icon-question-detail" />
            </div>
          </div>
        </div>

        <!-- 答 -->
        <div class="answer-box">
          <div :class="item.status == 3 ? 'answer-icon invalid-a' : 'answer-icon'">A</div>
          <div class="answer-text">
            <div :class="item.status == 3 ? 'answer-text greytext' : 'answer-text'" class="text-wrap">
              {{ item.orderQuestionReplyDTOList ? item.orderQuestionReplyDTOList[0].replyContext : '' }}
            </div>
            <a
              v-if="item.orderQuestionReplyDTOList && item.orderQuestionReplyDTOList[0].attachmentFile"
              style="margin-left: 10px; width: 500px; height: 30px"
              :href="
                (item.orderQuestionReplyDTOList && item.orderQuestionReplyDTOList[0].attachmentFile
                  ? item.orderQuestionReplyDTOList[0].attachmentFile
                  : null) | accelerateUrl
              "
              class="question-attachment dis-ib ellipsis"
              :title="
                item.orderQuestionReplyDTOList && item.orderQuestionReplyDTOList[0].attachmentFile
                  ? item.orderQuestionReplyDTOList[0].attachmentName
                  : null
              "
            >
              <img class="mr-1" :src="require('@/assets/image/link_icon1.png')" alt="" height="16px" />
              <span>{{
                item.orderQuestionReplyDTOList && item.orderQuestionReplyDTOList[0].attachmentFile
                  ? item.orderQuestionReplyDTOList[0].attachmentName
                  : null
              }}</span>
            </a>
          </div>
        </div>

        <div class="more-box" v-if="item.showQuestionDetail">
          <div class="line"></div>
          <div class="one-more-box" :key="item2.id" v-for="(item2, index) in item.orderQuestionDTOList">
            <div class="more-title">追问：</div>
            <div class="qa-box">
              <div style="margin-bottom: 15px">
                <div class="more-question">
                  <div :class="item.status == 3 ? 'question-icon invalid' : 'question-icon'">Q</div>
                  <div
                    :class="item.status == 3 ? 'question-text greytext' : 'question-text'"
                    class="text-wrap question-title"
                  >
                    {{ item2.questionContext }}
                  </div>
                  <!-- <div class="question-text">xx文件中有个单词译员不知道如何翻译？</div> -->
                  <div
                    class="question-continue"
                    @click="handleOpenReply(item2)"
                    v-if="
                      ((item2.questionerType == 1 && $g.isSup) || (item2.questionerType == 2 && $g.isCust)) &&
                      item2.status == 1 &&
                      item.status != 3
                    "
                  >
                    回应
                  </div>
                  <div
                    class="question-continue"
                    @click="handleOpenCuntinue(item, item2)"
                    v-if="
                      ((item2.questionerType == 1 && $g.isCust) || (item2.questionerType == 2 && $g.isSup)) &&
                      item2.orderQuestionReplyDTOList &&
                      item.status == 2 &&
                      index == item.orderQuestionDTOList.length - 1 &&
                      item.status != 3
                    "
                  >
                    追问
                  </div>
                </div>
                <a
                  style="margin-left: 30px; width: 400px"
                  :href="item2.attachmentFile | accelerateUrl"
                  class="question-attachment dis-ib ellipsis"
                  v-if="item2.attachmentFile"
                  :title="item2.attachmentName"
                >
                  <img class="mr-1" :src="require('@/assets/image/link_icon1.png')" alt="" height="16px" />
                  <span>{{ item2.attachmentName }}</span>
                </a>
              </div>

              <div style="margin-bottom: 20px">
                <div class="more-answer">
                  <div :class="item.status == 3 ? 'answer-icon invalid-a' : 'answer-icon'">A</div>
                  <div
                    :class="item.status == 3 ? 'answer-text greytext' : 'answer-text'"
                    class="text-wrap answer-content"
                  >
                    {{
                      item2.orderQuestionReplyDTOList && item2.orderQuestionReplyDTOList[0].replyContext
                        ? item2.orderQuestionReplyDTOList[0].replyContext
                        : ''
                    }}
                  </div>
                  <!-- <div class="answer-text">这么翻译这么翻译这么翻译这么翻译这么翻译这么翻译</div> -->
                </div>
                <a
                  style="margin-left: 30px; width: 400px"
                  :href="item2.orderQuestionReplyDTOList[0].attachmentFile | accelerateUrl"
                  v-if="item2.orderQuestionReplyDTOList && item2.orderQuestionReplyDTOList[0].attachmentName"
                  class="question-attachment dis-ib ellipsis"
                  :title="item2.orderQuestionReplyDTOList[0].attachmentName"
                >
                  <img class="mr-1" :src="require('@/assets/image/link_icon1.png')" alt="" height="16px" />
                  <span>{{ item2.orderQuestionReplyDTOList[0].attachmentName }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div :class="item.status == 3 ? 'qa-information invalid' : 'qa-information'">
          <div class="info-left">
            <div class="flex">
              <span :class="item.status == 3 ? 'info-title greytext' : 'info-title'">语言对：</span
              ><span
                v-if="item.sourceCode && item.targetCode"
                class="dis-ib ellipsis"
                style="width: 200px"
                :title="
                  $store.getters['app/getLangNameByCode'](item.sourceCode) +
                  ' > ' +
                  $store.getters['app/getLangNameByCode'](item.targetCode)
                "
                >{{ $store.getters['app/getLangNameByCode'](item.sourceCode) }} >
                {{ $store.getters['app/getLangNameByCode'](item.targetCode) }}</span
              >
            </div>
            <div>
              <span :class="item.status == 3 ? 'info-title greytext' : 'info-title'">创建人：</span
              ><span>{{ item.creatorName ? item.creatorName : '用户' }}</span>
            </div>
          </div>
          <div class="info-right">
            <div>
              <span :class="item.status == 3 ? 'info-title greytext' : 'info-title'">创建时间：</span
              ><span>{{ moment(item.createDate).format('YYYY-MM-DD HH:mm') }}</span>
            </div>
            <div>
              <span :class="item.status == 3 ? 'info-title greytext' : 'info-title'">更新时间：</span
              ><span>{{ moment(item.updateDate).format('YYYY-MM-DD HH:mm') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 创建问题 -->
    <CreateQuestionModal
      :questionVisible="createQuestionVisible"
      :info="info"
      @closeQuestionModal="closeQuestionModal"
      @confirm="getQuestionList(selectValue)"
    />

    <!-- 追问问题 -->
    <QuestionContinue
      :questionContinue="questionContinueVisible"
      :info="info"
      :question="questionDetail"
      :latestDetail="latestDetail"
      @closeQuestionContinue="questionContinueVisible = $event"
      @confirm="getQuestionList(selectValue)"
    />

    <!-- 问题答复 -->
    <QuestionReplyModal
      :questionreply="questionreplyVisible"
      :info="info"
      :question="questionDetail"
      @closeQuestionReply="questionreplyVisible = $event"
      @confirm="getQuestionList(selectValue)"
    />
  </a-drawer>
</template>
<script>
import CreateQuestionModal from './CreateQuestionModal.vue'
import QuestionContinue from './QuestionContinue.vue'
import QuestionReplyModal from './QuestionReplyModal.vue'
import moment from 'moment'
import debounce from 'lodash/debounce'

export default {
  name: 'OnlineConsultDrawer',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    CreateQuestionModal,
    QuestionContinue,
    QuestionReplyModal,
  },

  data() {
    return {
      moment,
      createQuestionVisible: false, // 创建问题
      questionContinueVisible: false, // 追问
      questionreplyVisible: false, //回复

      questionList: [], // 问题列表
      questionDetail: {}, //问题详情
      latestDetail: {}, //最新的问题详情

      selectValue: '', //选择的状态
    }
  },
  computed: {},
  watch: {
    visible(newValue) {
      if (newValue) {
        this.getQuestionList()
      } else {
        this.selectValue = ''
      }
    },
  },
  methods: {
    // 获取问题列表
    async getQuestionList(v) {
      const data = await this.$http('questionList', {
        orderId: this.info.id,
        status: v ? v : null,
      })
      let newList = data.map((item) => {
        return {
          ...item,
          showQuestionDetail: false,
        }
      })
      console.log('问题列表', newList)
      this.questionList = newList

      this.autoShowDetail() // 打开有追问的详情
    },

    // 自动打开详情
    autoShowDetail() {
      let newList = this.questionList
      for (let i = 0; i < newList.length; i++) {
        if (newList[i].orderQuestionDTOList && newList[i].orderQuestionDTOList.length > 0) {
          newList[i].showQuestionDetail = true
          this.questionList = newList
        }
      }
    },

    // 关闭在线咨询抽屉
    handleClose() {
      this.$emit('close', false)
    },
    createQuestion() {
      console.log('create')
      this.createQuestionVisible = true
    },
    // 关闭创建问题对话框
    closeQuestionModal(e) {
      console.log('close')
      this.createQuestionVisible = e
    },
    // 打开追问对话框
    handleOpenCuntinue(value, value2) {
      this.questionContinueVisible = true
      this.questionDetail = value
      this.latestDetail = value2
    },

    // 打开回应对话框
    handleOpenReply(value) {
      console.log('回应的值', value)
      this.questionDetail = value
      this.questionreplyVisible = true
    },
    // 关闭问题
    handleCloseConfirm: debounce(async function (id) {
      await this.$http({
        key: 'stoneCloseQuestion',
        params: {
          questionId: id,
        },
      })
      this.getQuestionList(this.selectValue)
    }, 1000),

    showDetail(id) {
      console.log('我点击的', id)
      let newList = this.questionList
      for (let i = 0; i < newList.length; i++) {
        if (newList[i].id == id) {
          newList[i].showQuestionDetail = !newList[i].showQuestionDetail
          this.questionList = newList
        }
      }
    },

    closeQuestionContinue() {},

    selectStatus(value) {
      this.getQuestionList(value)
    },

    downloadAttachment() {
      // 下载附件
    },
  },
  beforeDestroy() {},
}
</script>
